import React,{useState,useEffect} from 'react';
import axiosInstance from '../../../helpers/axios'
import Select from 'react-select';
import jwt_decode from 'jwt-decode';
import {useLocation} from 'react-router-dom'
import {
    Input,
    Table,
    Modal,
    Row,
    Col,
    Card,
    Button,
    ModalBody,
    ModalHeader,
    ModalFooter
 } from 'reactstrap'
 import * as moment from 'moment'
 import addNew from '../../../assets/images/Button.png'
 import ViewFile from './viewFile.js'
 import dele from '../../../assets/images/delete.png'
 import downloadFile from '../../../assets/images/downloadFile.png'
import imageFile from '../../../assets/images/imageFile.png'
import documentImage from '../../../assets/images/documenticon.png'
 import eye from '../../../assets/images/eye.png'
import { TransformWrapper,TransformComponent} from 'react-zoom-pan-pinch';



import 'bootstrap/dist/css/bootstrap.min.css';


export default function Files({fileList,userId,appId,profileDetails}) {
  const location=useLocation()
  const user=sessionStorage.getItem('user')
  const decode=jwt_decode(user);
  const [downloadFileArray,setDownloadFileArray]=useState([])
const [arrayLength,setArrayLength]=useState(0)

const [file, setFile] = useState([]);
  const [fileSize, setFileSize] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState([]);
  let preview = '';
  const fileTypes = ['JPG', 'PNG', 'PDF', 'JPEG'];

  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('Hold On...');


  const showUploadImage=(imgSrc)=>{

    setImageOpen(!imageOpen)
    setImageFile(imgSrc)

  }
  const handleChange = (currFile) => {
    setFile([]);
   
    setFile((curr) => [...curr, currFile[0]]);
    if (currFile[0]) {
      setUploaded(true);
      setFileSize(currFile[0].size);
      setFileType(currFile[0].type);
      setUploadedFileName(currFile[0].name);
    } else {
      console.log('no');
    }
  };

  preview = file ? URL.createObjectURL(new Blob(file, { type: 'application/jpg' })) : '#';
 // const location = useLocation();

  const [selectedValue, setSelectedValue] = useState('');

 const [id] = useState(userId);
  //file type enum
  const [Type, setType] = useState('');
  const [password, setPassword] = useState('');
  const [fileBinary, setFileBinary] = useState('');

  // handle onChange event of the select
  const handleSelect = (e) => {
    setType(e.id);
    setSelectedValue(e.value);
  };

  const DocCreate = (resp) => {
   
    let docData = null;
    if (userId) {
      setConfirmationDialog(!confirmationDialog);
      if(Type!=="driving_license_back"&&Type!=="aadhaar_back")
      {

        docData = {
          userId: userId,
          type: Type==="driving_license_front"||Type==="driving_license_back"?"driving_license":Type==="aadhaar_front"||Type==="aadhaar_back"?"aadhaar":Type,
          
          documentPassword:password,
          url1:resp.data.data.s3Url,
        };
      }
      else if( Type==="driving_license_back"||Type==="aadhaar_back")
      {
         docData = {
          userId: userId,
          type: Type==="driving_license_front"||Type==="driving_license_back"?"driving_license":Type==="aadhaar_front"||Type==="aadhaar_back"?"aadhaar":Type,
          url2:resp.data.data.s3Url,
          documentPassword:password,
          
        };
      }

      axiosInstance.post(`/users/documents/create/admin`, docData, {
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-Type': 'application/json',
          },
        })
        .then(
          (res) => {
            setConfirmationMessage('Uploaded Successfully');
            setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
            setTimeout(() => setConfirmationDialog(false), 2000);
            setTimeout(() => window.location.reload(true), 2000);
          },
          (error) => {
            setConfirmationMessage('Upload was Unsuccessful...!');
            setTimeout(() => setConfirmationDialog(!confirmationDialog), 3000);
            setTimeout(() => setConfirmationDialog(false), 3000);
          },
        );
    } else {
      alert('Fill all mandatory fields');
    }
  };

  const handleSubmission = (item) => {
    const data = new FormData();
    data.append('file', item[0]);
    data.append('type', Type);

    if(Type!=="video")
    {
          axiosInstance.post(`/file/upload`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(
        (response) => {
          DocCreate(response);
        },
        (error) => {
          alert('Check Mandatory Fields and File format');
        },
      );
    }
    else
    {
          axiosInstance.post(`/file/upload-base64`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(
        (response) => {
          DocCreate(response);
        },
        (error) => {
          alert('Check Mandatory Fields and File format');
        },
      );
    }
  
  };

  //remove

  const removeHandler = () => {
    setFile(null);
    setFileSize(null);
    setFileType(null);
    setUploadedFileName(null);
    setUploaded(false);
    preview = file ? URL.createObjectURL(new Blob(file, { type: 'application/jpg' })) : '#';
  };

  const options = [
    { value: 'PAN', label: 'PAN', id: 'pan' },
    { value: 'Video', label: 'Video', id: 'video' },
    { value: 'Aadhaar Front', label: 'Aadhaar Front', id: 'aadhaar_front' },
    { value: 'Aadhaar Back', label: 'Aadhaar Back', id: 'aadhaar_back' },
    { value: 'Driving License Front', label: 'Driving License Front', id: 'driving_license_front' },
    { value: 'Driving License Back', label: 'Driving License Back', id: 'driving_license_back' },
    { value: 'Voter ID', label: 'Voter ID', id: 'voterid' },
    { value: 'ITR', label: 'ITR', id: 'itr' },
    { value: 'Bank Statement 3m', label: 'Bank Statement 3m', id: 'bank_statement3' },
    { value: 'Bank Statement 6m', label: 'Bank Statement 6m', id: 'bank_statement6' },
    { value: 'Pay Slip 1st M', label: 'Pay Slip 1st M', id: 'pay_slip1' },
    { value: 'Pay Slip 2nd M', label: 'Pay Slip 2nd M', id: 'pay_slip2' },
    { value: 'Pay Slip 3rd M', label: 'Pay Slip 3rd M', id: 'pay_slip3' },
    { value: 'Offer letter', label: 'Offer letter', id: 'offer_letter' },
    { value: 'No Dues', label: 'No Dues', id: 'no_dues' },
    { value: 'Utility Bill', label: 'Utility Bill', id: 'utility_bill' },
    { value: 'Invoice', label: 'Invoice', id: 'invoice' },
    { value: 'Office Id', label: 'Office Id', id: 'office_id' },
    {
      value: 'Registration Certificate ',
      label: 'Registration Certificate',
      id: 'registration_certificate',
    },
    { value: 'Others', label: 'Others', id: 'others' },
  ];



    const logAddharAccess=()=>{
    

    const urlVerify=`/admin/application/aadhaar/admin?userId=${userId}&applicationId=${appId}`
    
    axiosInstance.get(urlVerify, {
      headers:{
        Authorization:`Bearer ${user}`
      }
    })
    .then((res)=>{
      if(decode?.role==="admin")
      {
        // const a = document.createElement('a')
        // a.href = res?.data?.data?.url1
        const aadharBack = res?.data?.data?.url2
        const urlVerify=`/file/url-content?path=${res?.data?.data?.url1}`
    
        axiosInstance.get(urlVerify, {
          headers:{
            Authorization:`Bearer ${user}`
          }
        }).then((res)=>{
          window.open(res?.data?.data,"_self")
          setTimeout(() => {
            const urlVerifyBack=`/file/url-content?path=${aadharBack}`
      
          axiosInstance.get(urlVerifyBack, {
            headers:{
              Authorization:`Bearer ${user}`
            }
          }).then((res)=>{
            window.open(res?.data?.data,"_self")
            // a.download = res?.data?.data?.url1.split('/').pop()
            // document.body.appendChild(a)
            // a.click()
            //
          })
          }, 1000);
          // a.download = res?.data?.data?.url1.split('/').pop()
          // document.body.appendChild(a)
          // a.click()
          // document.body.removeChild(a)
        })
       
        
        // const b = document.createElement('b')
        // b.href = res?.data?.data?.url2
        // b.download = res?.data?.data?.url2?.split('/').pop()
        // document.body.appendChild(b)
        // b.click()
        // document.body.removeChild(b)
      }
      else
      {
          setAadhaar(res?.data?.data)
          const c=document.getElementById('triggerAadhaar')
          c.click();
      }
    })
    .catch((err)=>{
      alert("Incorrect Password")
    }
    )
  }
  
  const onSelectHandler=(e,item)=>{
    console.log(item)
    if(downloadFileArray.find(arrItem=>arrItem===item))
     {
        downloadFileArray.splice(downloadFileArray.indexOf(item),1);
        setArrayLength(downloadFileArray.length)
     }
     else
     {
        downloadFileArray.push(item)
        setArrayLength(downloadFileArray.length)

     }

console.log(downloadFileArray,"array")
  }

  const deleteFile=(item)=>{
    
    const urlDelete='/users/documents/'
    const body={
    userId:item.userId,
    type:item.type,
    documentId:item.id,
    url1:item.url1,
    url2:item.url2

    }
    axiosInstance.post(urlDelete,body)
    .then((res)=>{
      
      alert("File Deleted")
      setTimeout(
        ()=>{
          window.location.reload()
        },1000)

    })
    .catch((err)=>{

    })

  }

  const [aadhaar,setAadhaar]=useState(null)
  const [itemPath,setitemPath] = useState(null)
  const [item2Path,setitem2Path] = useState(null)
  const [openAddFile,setOpenAddFile]=useState(false)
  const handleClick = ()=>{
    const url = `/file/url-content?path=${profileDetails?.aadhaarFrontImage}`
    axiosInstance
        .get(url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res)=>{
          setitemPath(res?.data?.data)
          })
          if(profileDetails?.aadhaarBackImage){
            const url1 = `/file/url-content?path=${profileDetails?.aadhaarBackImage}`
            axiosInstance
                .get(url1, {
                  headers: {
                    Authorization: `Bearer ${user}`,
                  },
                })
                .then((res)=>{
                  setitem2Path(res?.data?.data)
                  })
          }
         
  }
  return (
    <div style={{boxShadow:'0px 0px 2px 2px  #f0f0f0',borderRadius:'15px',marginTop:'1em'}}>
       <Modal style={{overflow:'auto',height:'40em'}} size="xl" isOpen={imageOpen}>
        <ModalHeader >Uploaded File <i>( Use Scroll to Zoom in the Picture )</i> <Button style={{position: 'absolute',right:'2em'}} type="button" onClick={()=>setImageOpen(!imageOpen)}>X</Button></ModalHeader>
        <ModalBody  style={{margin:'5%',border:'2px dashed #D0D0D0',display:'block',justifyContent:'center'}} >

        {aadhaar?.type==="aadhaar"?
         <TransformWrapper
            defaultScale={1}
           >
            <TransformComponent>
               <img style={{width:'100%'}} src={itemPath} alt="File"/>
               {profileDetails?.aadhaarBackImage?<img src={item2Path} style={{width:'100%'}} alt="file"/>:null}
            </TransformComponent>
        </TransformWrapper>:null
          } 
     
        
      
          </ModalBody>
      </Modal>
      <img style={{visibility:'hidden'}} src={eye} id="triggerAadhaar" onClick={()=>{setImageOpen(!imageOpen);handleClick()}}/>
      <Modal size="lg" isOpen={openAddFile}>
        <ModalHeader style={{backgroundColor:'#F0F0F0',display:'flex',justifyContent: 'center',}}><span style={{fontWeight:'bold'}}>Upload File</span></ModalHeader>
        <ModalBody  style={{height:'30em'}}>
       <Row>
          <Col style={{ marginLeft: '10px' }}>
            <Card style={{ paddingLeft: '20px',  width: '400px' }}>
              <div style={{ display: 'block' }}>
                <div style={{ paddingLeft: '20px', width: '250px' ,marginTop:'1em'}}>
                  <Select
                    options={options}
                    placeholder="Select file to upload"
                    onChange={handleSelect}
                  />
                  {selectedValue === 'Others'  ? (
                    <span>
                      {' '}
                     
                      <input
                        style={{ marginTop: '1em', paddingLeft: '5px', borderRadius: '5px' }}
                        type="text"
                        placeholder="File Name"
                        onChange={(e) => setType(e.target.value)}
                        id="others"
                      />{' '}
                      <input
                        style={{ marginTop: '1em', borderRadius: '5px', paddingLeft: '5px' }}
                        type="text"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password If Any"
                        id="othersId"
                      />
                    </span>
                  ) : (
                    <> {selectedValue !== '' ?<input
                        style={{ marginTop: '1em', borderRadius: '5px', paddingLeft: '5px' }}
                        type="text"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password If Any"
                        id="othersId"
                      />:null}</>
                  )}
                  {selectedValue === '' ? (
                    <span style={{ color: 'red' }}>
                      {' '}
                      <br /> Please Select a File Category
                    </span>
                  ) : (
                    <></>
                  )}
                  
                </div>
              
                <div style={{ paddingLeft: '20px', }}>
                  <p>File Size: {fileSize ? `${fileSize} Kb` : ''} </p>
              
                  <p>File Type: {fileType}</p>
                </div>
                <div style={{ display: 'flex', position: 'absolute', height: '150px' }}>
                  <div style={{ position: 'absolute' }}>
                    <input
                      type="file"
                      style={{
                        border: '2px dashed blue',
                        width: '20em',
                        marginLeft: '1em',
                        padding: '1em',
                      }}
                      onChange={(e) => {
                        handleChange(e.target.files);
                      }}
                      accept="image/*"
                    />

                    {uploaded && (
                      <Button
                         
                        style={{
                          backgroundColor:'#d32028',
                          border:'none',
                          width: '120px',
                          marginLeft: '5em',
                        }}
                        type="button"
                        onClick={() => handleSubmission(file)}
                      >
                       
                          Submit File
                     
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col>
            {uploaded ? (
              <div style={{ height: '200px', width: '200px' }}>
                <img
                  style={{ height: '200px', width: '200px' }}
                  id="output"
                  src={preview}
                  alt="preview"
                />
                <button
                  style={{ position: 'absolute', right: '10em' }}
                  type="button"
                  onClick={() => removeHandler()}
                >
                  X
                </button>
              </div>
            ) : (
              <p>Upload File To See Preview </p>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={()=>setOpenAddFile(false)}>
          Close
        </Button>
      
      </ModalFooter>
      </Modal>

      <div style={{ padding:'1em',display:'flex',justifyContent: 'flex-end',}}>
        
        
        <div style={{display:'flex'}}>
          {arrayLength?<img style={{cursor:'pointer'}} src={downloadFile}/>:null}
        <img style={{cursor:'pointer'}} onClick={()=>setOpenAddFile(true)} src={addNew} alt='Add New file'/>
        </div>
      </div>

        <Table>
          <thead style={{backgroundColor:'#F9FAFB',height:'3em', }}>
            <th ><input  style={{height:'1em',width:'3em',marginLeft:'1em'}} type="checkbox"/></th>
            <th style={{fontSize:'14px',}}>File Name</th>
            <th style={{fontSize:'14px',width:'20em'}}>Date Uploaded</th>
            <th style={{fontSize:'14px',}}>File Type</th>
            <th style={{fontSize:'14px',}}>Password ( if any )</th>
            <th style={{fontSize:'14px',textAlign:'center'}}>Action</th>
          </thead>
          <tbody>
            {fileList?.map((item, index)=>{
              return (

                  <tr key={index}>
              <td style={{width:'6em'}}><input onClick={(e)=>onSelectHandler(e,item)} style={{height:'1em',width:'2em',marginLeft:'1em'}}  type="checkbox"/></td>
              <td style={{fontSize:'14px',}}>
                <div style={{display:'flex',justifyContent:'flex-start',}}>
                  <img src={documentImage}/>
                  <span style={{textAlign:'left',paddingLeft:'10px'}}>{item.type} </span>
                </div>
                </td>
              <td style={{fontSize:'14px'}}>{moment(item.updatedAt).format('DD MMM YYYY')}</td>
              <td style={{fontSize:'14px',}}>{item.type} </td>
              <td style={{fontSize:'14px',textAlign:'center'}}>{item.documentPassword?item.documentPassword:"--"} </td>
              <td style={{fontSize:'14px',}}><div style={{display:'flex',justifyContent: 'space-evenly',marginTop:'1em'}}><ViewFile currUserId={item.userId} currApplicationId={appId} type={item.type} item={item.url1} item2={item.url2} image={eye} profileDetails={profileDetails}/>{location.pathname==="/status/submission/profile"||location.pathname==="/status/review/profile"? <img style={{cursor:'pointer'}} onClick={()=>deleteFile(item)} src={dele}/>:null}</div></td>
            </tr>
              )
            })}
             <tr>
              <td style={{width:'6em'}}><input style={{height:'1em',width:'2em',marginLeft:'1em'}}  type="checkbox"/></td>
              <td style={{fontSize:'14px',}}>
                <div style={{display:'flex',justifyContent:'flex-start',}}>
                  <img src={documentImage}/>
                  <span style={{textAlign:'left',paddingLeft:'10px'}}>Aadhaar </span>
                </div>
                </td>
              <td style={{fontSize:'14px'}}>Hidden</td>
              <td style={{fontSize:'14px',}}>image</td>
              <td style={{fontSize:'14px',textAlign:'center'}}>--</td>
              {decode?.role==="admin"?<td style={{fontSize:'14px',}}><div style={{display:'flex',justifyContent: 'space-evenly',marginTop:'1em'}}><img src={downloadFile} style={{width:'6em'}} onClick={()=>logAddharAccess()}/>{location.pathname==="/status/submission/profile"||location.pathname==="/status/review/profile"? <img style={{cursor:'pointer',width:'1.5em',height:'1.7em'}} onClick={()=>deleteFile()} src={dele}/>:null}</div></td>:<td style={{fontSize:'14px',}}><div style={{display:'flex',justifyContent: 'space-evenly',marginTop:'1em'}}><img src={eye}  onClick={()=>logAddharAccess()}/>{location.pathname==="/status/submission/profile"||location.pathname==="/status/review/profile"? <img style={{cursor:'pointer'}} onClick={()=>deleteFile()} src={dele}/>:null}</div></td>}
            </tr>
          </tbody>
        </Table>
    </div>
  )
}
